import './Cabecalho.css'

import Container from 'react-bootstrap/Container';
import {Nav, Navbar} from 'react-bootstrap';
import NavLinks from './NavLinks';
import { useState } from 'react';

import I18n from '../I18n';
import Translator from '../I18n/Translator';

import { TemaContext } from '../../common/context/TemaContext';
import { useContext, useEffect } from 'react';
import { LinguagemContext } from '../../common/context/LinguagemContext';

const Cabecalho = ({ocultaLinks, origem}) => {

    const {theme, setTheme} = useContext(TemaContext);
    const {language} = useContext(LinguagemContext);
    const [isResumo, setIsResumo] = useState(false);
    const [isClimate, setIsClimate] = useState(false);
    const [isMetagenomic, setIsMetagenomic] = useState(false);
    const [isNormal, setIsNormal] = useState(false);

    const mudaTema = (temaAtual) => {
        let novoTema = temaAtual === "dark" ? "light" : "dark";
        localStorage.setItem("theme", novoTema);
        return novoTema;
    }    

    useEffect(() => {
        var dominio = window.location.hostname;
        const fullUrl = window.location.href;

        let resumo = fullUrl.includes("maparesumido");
        let climate = fullUrl.includes("climate");
        let metagenomic = fullUrl.includes("metagenomic");
        let normal = !resumo && !climate && !metagenomic;

        setIsResumo(resumo);

        setIsClimate(climate);

        setIsNormal(normal);

        setIsMetagenomic(metagenomic);
    }, []);
    
    return (

            <Navbar className='opaco' expand="lg" fixed='top'>
                <Container className='navbar_container'>                 
                    <a href='/'>
                        <img className='aesop_logo' src="imagens/logo_aesop.png"   alt="Logotipo AESOP"/>
                    </a>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />

                    <Navbar.Collapse id="basic-navbar-nav">
                        <div className='sub-titulo'> {isMetagenomic && <Translator path={"dashboard.title_metagenomic"}/>} {isClimate && <Translator path={"dashboard.title_climate"}/>}  {isResumo &&  <Translator path={"dashboard.title_resumo"}/>} {isNormal &&  <Translator path={"dashboard.title"}/>}   </div>
                        <div className='sub-titulo2'> <Translator path={"dashboard.subtitle"} /></div>
                    
                        <NavLinks linguagem = {language} ocultaLinks = {ocultaLinks} origem = {origem}></NavLinks>
                        <div className='right_images'>
                            
                            <div className='mini_icons'>
                                <I18n theme={theme}></I18n>
                                <div className={"switch_color " + theme} onClick={() => {setTheme(mudaTema(theme));}}></div>
                            </div> 

                            <div className="logos_direita">
                                <a href="https://portal.fiocruz.br/">
                                    <img src="imagens/fiocruz-logo.png" className="img-fiocruz" alt="Fiocruz Logo"/>
                                </a>
                                <a href="https://coppe.ufrj.br/">
                                    <img src="imagens/logos_parceiros/coppe-logo.png" className="img-coppe" alt="Coppe UFRJ Logo"/>
                                </a>
                                <a href="https://www.gov.br/saude/pt-br">
                                    <img src="imagens/ministerio_saude_logo_contorno.png" className="img-min-saude" alt="Ministério da Saúde Logo"/>
                                </a>
                                <a href="https://www.rockefellerfoundation.org/">
                                    <img src="imagens/therockefellerfondation_.gif" className="img-rockfeller" alt="Fundação Rockefeller Logo"/>
                                </a>
                            </div>  
                        </div>
                        
                        
                    </Navbar.Collapse>  
                        
                </Container> 
                           
            </Navbar>
             

    )

    /*return (
        <Navbar expand="lg" fixed='top'>
          <Navbar.Brand href="#home">
            <a href='/'>
                    <img className='aesop_logo' src="imagens/logo_aesop.png"   alt="Logotipo AESOP"/>
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            
              <Nav.Link><I18n childToParent={childToParent} linguagem={linguagem} theme={theme}></I18n></Nav.Link>
              <div className={"switch_color " + theme} onClick={() => {themeToggler(); childToParent(linguagem);}}></div>
              <div className='espacamento'></div>
              <Nav.Link><img src="imagens/fiocruz-logo.png" className="img-fiocruz" alt="Fiocruz Logo"/></Nav.Link>
              <Nav.Link><img src="imagens/ministerio_saude_logo_contorno.png" className="img-min-saude" alt="Ministério da Saúde Logo"/></Nav.Link>
              <Nav.Link><img src="imagens/therockefellerfondation_.gif" className="img-rockfeller" alt="Fundação Rockefeller Logo"/></Nav.Link>
            
          </Navbar.Collapse>
        </Navbar>
    )*/
}

export default Cabecalho