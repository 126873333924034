import style from './Metagenomica.module.css'
import './Metagenomica.css'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Container, Nav, Row } from 'react-bootstrap'
import { TemaContext } from '../../common/context/TemaContext';
import { LinguagemContext } from '../../common/context/LinguagemContext';
import { UsuarioContext } from '../../common/context/UsuarioContext';
import { useNavigate} from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import Translator from '../I18n/Translator';
import L from 'leaflet';
import { Box, Slider, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem, Chip, OutlinedInput, Stack} from '@mui/material';
import Moment from 'react-moment';
import 'chartjs-adapter-moment';
import {Line } from 'react-chartjs-2';
import PopupGrafico from '../PopupGrafico';
import Footer from '../Footer';
import moment from 'moment';
import { t, use } from 'i18next';
import GeoRasterLayer from 'georaster-layer-for-leaflet';
import chroma from 'chroma-js';
import { re } from 'mathjs';
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

var cod_estados = [
    {"UF":"AC",
    "COD": 12, "NOME":"Acre"},
    {"UF":"AL",
    "COD": 27, "NOME":"Alagoas"},
    {"UF":"AP",
    "COD": 16, "NOME":"Amapá"},
    {"UF":"AM",
    "COD": 13, "NOME":"Amazonas"},
    {"UF":"BA",
    "COD": 29, "NOME":"Bahia"},
    {"UF":"CE",
    "COD": 23, "NOME":"Ceará"},
    {"UF":"DF",
    "COD": 53, "NOME":"Distrito Federal"},
    {"UF":"ES",
    "COD": 32, "NOME":"Espírito Santo"},
    {"UF":"GO",
    "COD": 52, "NOME":"Goiás"},
    {"UF":"MA",
    "COD": 21, "NOME":"Maranhão"},
    {"UF":"MT",
    "COD": 51, "NOME":"Mato Grosso"},
    {"UF":"MS",
    "COD": 50, "NOME":"Mato Grosso do Sul"},
    {"UF":"MG",
    "COD": 31, "NOME":"Minas Gerais"},
    {"UF":"PA",
    "COD": 15, "NOME":"Pará"},
    {"UF":"PB",
    "COD": 25, "NOME":"Paraíba"},
    {"UF":"PR",
    "COD": 41, "NOME":"Paraná"},
    {"UF":"PE",
    "COD": 26, "NOME":"Pernambuco"},
    {"UF":"PI",
    "COD": 22, "NOME":"Piauí"},
    {"UF":"RJ",
    "COD": 33, "NOME":"Rio de Janeiro"},
    {"UF":"RN",
    "COD": 24, "NOME":"Rio Grande do Norte"},
    {"UF":"RS",
    "COD": 43, "NOME":"Rio Grande do Sul"},
    {"UF":"RO",
    "COD": 11,"NOME":"Rondônia"},
    {"UF":"RR",
    "COD": 14,"NOME":"Roraima"},
    {"UF":"SC",
    "COD": 42,"NOME":"Santa Catarina"},        
    {"UF":"SP",
    "COD": 35,"NOME":"São Paulo"},
    {"UF":"SE",
    "COD": 28,"NOME":"Sergipe"},
    {"UF":"TO",
    "COD": 17,"NOME":"Tocantins"},
];

var posicao = localStorage.getItem('posicaoScroll');

const Metagenomica = () => {
    const {theme} = useContext(TemaContext);
    const {language} = useContext(LinguagemContext);
    const {logado} = useContext(UsuarioContext);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const position = [-15.0, -54.27390005057997];
    const defaultZoom = 3.00;
    const zoomSnap_ = 0.25;
    const wheelPxPerZoomLevel_ = 120;

    var alterandoData = false;

    var weightNormal = theme === "dark" ? 0.5 : 0.3;
    var weightBrasil = 1.5;

    var weightSelecao = 2.5; 

    const transparencia = useRef(0.5);

    var opacidadeMunicipio = 1.0;

    const transparenciaDefaultMapa = useMemo(() => {
        return "rgba(255, 255, 255, 0)";
    }, []);
    const cinzaDefaultMapaDark = useMemo(() => {
        return "#272727";
    }, []);
    const cinzaDefaultMapaBorda = useMemo(() => {
        return "#C1c3c3";//"rgba(221, 220, 223, 0.3)";
    }, []);

    const circles = useRef([]);  // useRef para armazenar os círculos no mapa

    let isResponsive = true;

    let maintainAspectRatio = false;

    let leftGrafico = 0;
    let rightGrafico = 0;
    let topGrafico = 0;
    let bottomGrafico = 0;

    const valorNil = "nil";

    var corLinhaMarcaoGrafico = (theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(94, 94, 94, 1)");

    var texto_grafico_dark = "#fff";
    var texto_grafico_light = "#000";
    var fontGrafico = 10;

    const fixaCasasDecimais = (valor, casasDecimais) => {
        if(valor === valorNil) 
            return valorNil;
        return parseFloat(valor).toFixed(casasDecimais).replace(',', '.');
    }

    function exibeMesAnoGrafico(index, values){
        const totalTicks = values.length;

        const date = moment(values[index].value);
        
        // Sempre mostre o primeiro e o último tick
        if (index === 0 || index === totalTicks - 1) {
            return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
        }
        // Lógica para mostrar ticks intermediários
        let intermediateTicks = 6; // número de ticks intermediários desejados

        let totalWeekTicks = Math.floor(totalTicks / 7);

        if(totalWeekTicks < intermediateTicks)
            intermediateTicks = totalWeekTicks - 2;
            if(intermediateTicks < 0)
                intermediateTicks = 0;

        const stepSize = Math.floor(totalTicks / (intermediateTicks + 1));

        if (index % stepSize === 0) {
            return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
        }

        return null; // Não mostra outros ticks
    }

    var geojsonBrasil = (require('./Dados/shapefile/BR_UF_2022.json'));
    var geojsonMunicipios = (require('./Dados/shapefile/BR_Municipios_2022.json'));

    /*Variáveis de Data*/
    const [semanaSelecionada, setSemanaSelecionada] = useState(0);
    const [inicioSemanaSelecionada, setInicioSemanaSelecionada] = useState(null);
    const [fimSemanaSelecionada, setFimSemanaSelecionada] = useState(null);
    const [inicioSemanaSelecionadaRange, setInicioSemanaSelecionadaRange] = useState(null);
    const [fimSemanaSelecionadaRange, setFimSemanaSelecionadaRange] = useState(null);
    const [semanas, setSemanas] = useState([]);
    const [inicioSemanas, setInicioSemanas] = useState([]);
    const [fimSemanas, setFimSemanas] = useState([]);
    const [idxDataSelecionada, setIdxDataSelecionada] = useState(0);
    const [dataSelecionada, setDataSelecionada] = useState(0);
    const [minDate, setMinDate] = useState(null);
    const [minDateRangeInicial, setMinDateRangeInicial] = useState(null);
    const [minDateRangeFinal, setMinDateRangeFinal] = useState(null);
    const [minIdxDate, setMinIdxDate] = useState(0);
    const [maxDate, setMaxDate] = useState(null);
    const [maxDateRangeInicial, setMaxDateRangeInicial] = useState(null);
    const [maxDateRangeFinal, setMaxDateRangeFinal] = useState(null);
    const [maxIdxDate, setMaxIdxDate] = useState(0);
    const [datas, setDatas] = useState([]);
    const [maxLengthData, setMaxLengthData] = useState(0);
    /*Fim Variáveis de Data*/

    const [indice, setIndice] = useState("mob");

    const legend = useRef(null);

    var layer_ = useRef(null);

    const geoRaster = useRef(null);
    
    const [dados_municipio_selecionado, setDados_Municipio_Selecionado] = useState(null);

    const min = useRef(0);
    const max = useRef(0);
    const range = useRef(0);
    
    posicao = localStorage.getItem('posicaoScroll');
    var nomes_estados = (require('./Dados/estados.json'));

    //refs mapa
    const layerTile = useRef(null);
    const layerControl = useRef(null);
    let map = useRef(null);

    //refs shapefiles
    const shapeFileBrasil = useRef(null);
    const shapeFileMunicipios = useRef(null);

    const iniciou = useRef(false);

    let val_Rank = [
        t("dashboard.mobilidade"),
        t("dashboard.densidade"),
        "IBP",
        "DSEI"
    ];

    var [selectedRank, setSelectedRank] = useState(val_Rank);

    function getOptions_Line(theme, perc, titulo){    
        return {
          responsive: isResponsive,
          maintainAspectRatio: maintainAspectRatio,
          layout:{
            padding: {
                left: leftGrafico,
                right: rightGrafico,
                top: topGrafico,
                bottom: bottomGrafico
            }
          },
          plugins: {
              annotation: {
                  annotations: [
                    {
                      type: 'line',
                      mode: 'vertical',
                      scaleID: 'x',
                      value: dataSelecionada,
                      borderColor: corLinhaMarcaoGrafico,
                      borderWidth: 1,
                    },
                  ],
                },
            tooltip: {
              callbacks: {
                /*label: context => {
                  context["formattedValue"] = perc ? fixaCasasDecimais(context["formattedValue"],2) + "%" : context["formattedValue"];
                  if(context["element"]["options"]["backgroundColor"] === "rgba(255, 255, 255, 1)")
                    return "";
  
                  return getDataLabelGrafico(context);
                }*/
              }
            },
            legend: {
              display: true,
              onClick: null,
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: { 
                  size: 10
                }
              }
            },
            title: {
              display: false,
              text: titulo,
              color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
              position: 'top',
              font:{
                size: 13
              }
            },
          },
          scales: {
            y: {  // not 'yAxes: [{' anymore (not an array anymore)  
              ticks: {
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light, // not 'fontColor:' anymore
                // fontSize: 18,
                font: {
                  size: fontGrafico, // 'size' now within object 'font {}'
                },
                //stepSize: passo,
                beginAtZero: false,
                callback: function(value, index, ticks) {
                  return value + (perc === true ? '%' : '');
                }
              },
              beginAtZero: false,
            },
            /*x: {  // not 'xAxes: [{' anymore (not an array anymore)
              type: 'time',
              time: {
                parser: 'YYYY/MM/DD',
                unit: 'day',
                displayFormats: {
                 'day': 'DD/MM/YYYY',
                 'month' : 'DD/MM/YYYY'
                },
                
              },
              ticks:{
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: {
                  size: fontGrafico, // 'size' now within object 'font {}'
                },
                //stepSize: 7,
                callback: function(value, index, values) {
                    
                    return exibeMesAnoGrafico(index, values);
                },
              },
              min: datas != null ? datas[minIdxDate] : null,
              max: datas != null ? datas[maxIdxDate] : null,
            }*/
          }
          
        }
    }

    const handleChangeSelectData = (event) => {
        if(event.target.value > (datas.length -1)){
           /* console.log(inicioSemanas);*/
            setIdxDataSelecionada(datas.length -1);
            setDataSelecionada(datas[datas.length -1]);
            setSemanaSelecionada(semanas[semanas.length -1]);
            setInicioSemanaSelecionada(inicioSemanas[inicioSemanas.length -1]);
            setFimSemanaSelecionada(fimSemanas[fimSemanas.length -1]);
        } else {
            setIdxDataSelecionada(event.target.value);
            setDataSelecionada(datas[event.target.value]);
            setSemanaSelecionada(semanas[event.target.value]);
            setInicioSemanaSelecionada(inicioSemanas[event.target.value]);
            setFimSemanaSelecionada(fimSemanas[event.target.value]);
        }
    };

    const mudarData = (idx, datas_local) => {
        setLoading(true);
        if (alterandoData && !datas_local)
            return;

        if (idx > datas_local.length - 1) {
            idx = 0;
        } else if (idx < 0) {
            idx = datas_local.length - 1;
        }

        if(map.current  !== null)
            map.current.closePopup();

        alterandoData = true;

        setIdxDataSelecionada(idx);
        setDataSelecionada(datas_local[idx]);
        setSemanaSelecionada(semanas[idx]);
        setInicioSemanaSelecionada(inicioSemanas[idx]);
        setFimSemanaSelecionada(fimSemanas[idx]);

        alterandoData = false;
    }

    function getCidades(somenteNomes) {
        var nomes_municipios = [];

        if(somenteNomes){
            nomes_municipios.unshift(
                t("dashboard.todos")
            );
            nomes_municipios.sort((a, b) => a.localeCompare(b));
        } else {
            nomes_municipios.unshift({
                "cod_ibge": 0,
                "municipio": t("dashboard.todos")
            });
            nomes_municipios.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
        }

        return nomes_municipios;
    }

    const getEstados = () => { 
        let nomes_estados_tmp = [];
        
        for (let i=0; i < nomes_estados.length; i++){
            nomes_estados_tmp.push(nomes_estados[i]);
        }

        nomes_estados_tmp.unshift({
            "UF": "All",
            "ESTADO": t("dashboard.todos")
        });         
        
        return nomes_estados_tmp;
    }

    const [loading, setLoading] = useState(true);
    const [cod_ibge, setCod_Ibge] = useState(t("dashboard.todos"));
    const [municipioSelecionado, setMunicipioSelecionado] = useState(t("dashboard.todos"));
    const [estadoSelecionado, setEstadoSelecionado] = useState(t("dashboard.todos"));
    const [listaMunicipios, setListaMunicipios] = useState(getCidades());
    const [listaEstados, setListaEstados] = useState(getEstados());
    const [cidades_labels, setCidades_Labels] = useState([{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}]);
    const [mapa_cor_value, setMapa_Cor_Value] = useState("jet");

    const [dados_Municipio, setDados_Municipio] = useState(null);

    const [ultimoValorTEMP, setUltimoValorTEMP] = useState(null);
    const [ultimoValorUM, setUltimoValorUM] = useState(null);
    const [ultimoValorPREC, setUltimoValorPREC] = useState(null);

    const [dadosGenomica, setDadosGenomica] = useState(null);
    
    const defaultPropsMunicipio = {
        options: cidades_labels,
        getOptionLabel: (option) => option.label,
    };

    const isAllSelect = (variavel) => {
        return variavel === "All" || variavel === "Todos";
    }

    useEffect(() => {
        if(shapeFileMunicipios.current != null)
            plotaMapa();
    }, [cod_ibge]);

    function converterDiaDoAnoParaData(ano, diaDoAno) {
        var data = new Date(ano, 0); // Inicia em 0 (janeiro)
        data.setDate(diaDoAno); // Define o dia do ano
        
        // Formata a data para um formato legível
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        var dataFormatada = data.toLocaleDateString('pt-BR', options);
        
        return dataFormatada;
    }

/*
    useEffect(() => {
    
    }, [dados_Municipio]);
    */

   /*
    useEffect(() => {

    }, [mapa_cor_value]);
    */

    const getNomeEUFCidadeWhereCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local)){
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if(elementLoop !== undefined){
                        let cod_temp = parseInt(elementLoop["feature"]["properties"]["CD_MUN"]);
                        if(elementLoop["feature"]["properties"]["CD_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  [elementLoop["feature"]["properties"]["NM_MUN"], elementLoop["feature"]["properties"]["SIGLA_UF"]];
                            break;
                        }
                    } else {
                        retorno = [cod_ibge_local, ""];
                    }
                }
            } 
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    const getNomeCidadeWhereCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local)){
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if(elementLoop !== undefined){
                        let cod_temp = parseInt(elementLoop["feature"]["properties"]["CD_MUN"]);
                        if(elementLoop["feature"]["properties"]["CD_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  elementLoop["feature"]["properties"]["NM_MUN"];
                            break;
                        }
                    } else {
                        retorno = cod_ibge_local;
                    }
                }
            } 
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    const getCodIbgeShapefile = (shapefile, shape) => {
        let valor = shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"];
        if(typeof shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"] === "number"){
            valor = shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"].toString();
        }
        return (valor);
    }

    const handleChangeMunicipioNome = (nome_municipio) => {
        if (map.current !== null)
            map.current.closePopup();

        /*console.log("handleChangeMunicipioNome: ");*/

        if(isAllSelect(nome_municipio)){
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                let corBordaAnt = shapeFileMunicipios.current["_layers"][shape]["options"]["color"];
                if(corBordaAnt === "#dddcdf"){
                    corBordaAnt = "black"; 
                }
                shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightNormal, color: corBordaAnt, fillOpacity: 1.0 });
            });

            setCod_Ibge(nome_municipio);
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
            setDados_Municipio_Selecionado(null);
        } else {
            setCod_Ibge(nome_municipio);
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
            
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if (nome_municipio === getCodIbgeShapefile(shapeFileMunicipios.current, key)) {
                        map.current.fitBounds(elementLoop.getBounds().pad(1));
                        break;
                    }
                }
            } 
        }
    };

    var estados_labels = [
        {label:t("dashboard.todos")},
        {label:"Acre"},
        {label:"Alagoas"},
        {label:"Amapá"},
        {label:"Amazonas"},
        {label:"Bahia"},
        {label:"Ceará"},
        {label:"Distrito Federal"},
        {label:"Espírito Santo"},
        {label:"Goiás"},
        {label:"Maranhão"},
        {label:"Mato Grosso"},
        {label:"Mato Grosso do Sul"},
        {label:"Minas Gerais"},
        {label:"Pará"},
        {label:"Paraíba"},
        {label:"Paraná"},
        {label:"Pernambuco"},
        {label:"Piauí"},
        {label:"Rio de Janeiro"},
        {label:"Rio Grande do Norte"},
        {label:"Rio Grande do Sul"},
        {label:"Rondônia"},
        {label:"Roraima"},
        {label:"Santa Catarina"},        
        {label:"São Paulo"},
        {label:"Sergipe"},
        {label:"Tocantins"},
    ];

    var defaultProps = {
        options: estados_labels,
        getOptionLabel: (option) => option.label,
    };

    var zoomPorClique = false;

    useEffect(() => {     
        /*if (!logado) {
            navigate("/");
            return;
        }*/

        var dominio = window.location.hostname;


        /*//// Use fetch para carregar o arquivo JSON
        fetch('Dados/dados_pais/dados_brasil.json')
        .then(response => response.json())
        .then(data => {
            setDados_Municipios(data); // Atribui os dados JSON à variável global
        })
        .catch(error => {
            console.error('Erro ao carregar o arquivo JSON:', error);
        });

        getSemana();*/

        fetch('Dados/genomic.json') // Use fetch para carregar o arquivo JSON
        .then(response => response.json())
        .then(data => {
            setDadosGenomica(data); // Atribui os dados JSON à variável global
        })
        .catch(error => {
            console.error('Erro ao carregar o arquivo JSON:', error);
        });

        if (shapeFileBrasil.current === null) {
            shapeFileBrasil.current = L.geoJSON(geojsonBrasil, {
                interactive: false,
                style: function (feature) {
                    return { color: "black", fillColor: transparenciaDefaultMapa, stroke: true, weight: weightNormal, fillOpacity: transparencia.current };
                },
                /*onEachFeature: onEachFeatureBrasil,*/
            });
        }

        if (shapeFileMunicipios.current === null) {
            shapeFileMunicipios.current = L.geoJSON(geojsonMunicipios, {
                style: function (feature) {
                    return { color: "gray", fillColor: transparenciaDefaultMapa, stroke: true, weight: weightNormal, fillOpacity: opacidadeMunicipio };
                },
                onEachFeature: onEachFeatureEstado,
            });
        }

        iniciou.current = true;
        localStorage.setItem('posicaoScroll', JSON.stringify(0));
        //getDadosPorData(datas[idxDataSelecionada], language, true);
        //setDados_Municipios(dados_municipios_semanal);

        
    }, []);

    useEffect(() => {
        if(!iniciou.current)
            return;

        var url = theme === "dark" ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";

        const { innerWidth: width, innerHeight: height } = window;

        layerTile.current = L.tileLayer(url, {
            zoomControl: true,
            zoomSnap: zoomSnap_,
            zoomDelta: zoomSnap_,
            wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
            defaultZoom: defaultZoom,
            maxZoom: 18,
            id: 'mapbox.streets'
        });

        if (map.current === null) {
            map.current = L.map("map", {
                minZoom: 4,
                maxZoom: 10,
                layers: [layerTile.current]
            });

            shapeFileMunicipios.current.addTo(map.current);
            shapeFileBrasil.current.addTo(map.current);
        } else {
            map.current.invalidateSize();
        }

        const defaultCenter = position;

        if(width < 1300)
            map.current.setView([-13.338793, -45.206666], defaultZoom);
        else 
            map.current.setView(defaultCenter, defaultZoom);

        if(shapeFileMunicipios.current === null){
            shapeFileMunicipios.current = L.tileLayer('');
        }

        const lineCoordinates = [
            [-22.890090, -42.029405], // Latitude e longitude do ponto inicial
            [-12.924167, -38.440701],  // Latitude e longitude do ponto final
          ];

        const lineCoordinates2 = [
            [-23.62340166156419, -46.65858576087318], // Latitude e longitude do ponto inicial
            [-12.988999467983497, -38.50386516595166],  // Latitude e longitude do ponto final
          ];

        if (map.current) {
            // Adiciona um ouvinte de evento para o evento de zoomend
            const onZoomEnd = (event) => {
              // Obtém o nível de zoom atual
              const currentZoom = event.target.getZoom();

              if(theme === "dark"){
                if(currentZoom <=  5){
                    weightNormal = 0.5;
                }  else {
                    weightNormal = 1;
                }
              }
              //console.log(currentZoom);
      
            };
      
            // Adiciona o ouvinte de evento ao mapa para o evento de zoomend
            map.current.on('zoomend', onZoomEnd);
        } 
        
    }, [map]);

    useEffect(() => {
        if ((map.current === null))
            return;

        if (layerTile.current !== null) {
            map.current.removeLayer(layerTile.current);
        }

        var url = theme === "dark" ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";

        const defaultCenter = position;

        layerTile.current = L.tileLayer(url, {
            zoomControl: true,
            zoomSnap: zoomSnap_,
            zoomDelta: zoomSnap_,
            wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
            defaultZoom: defaultZoom,
            maxZoom: 18,
            id: 'mapbox.streets'
        });
        /*map.current.setView(defaultCenter, defaultZoom);*/
        layerTile.current.addTo(map.current);

    },[theme]);

    useEffect(() => {/*
        if(max.current === 0 && min.current === 0){

        } else {
            data_colormap.current = calcularFracoes(min.current, max.current);
            preparaColorMap();
        }
        if(isAllSelect(estadoSelecionado)){
            setEstadoSelecionado(t("dashboard.todos"));
        }

        estados_labels[0] = {label:t("dashboard.todos")};

        if(isAllSelect(municipioSelecionado)){
            setListaMunicipios(getCidades());
            setMunicipioSelecionado(t("dashboard.todos"));
            let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
            setCidades_Labels(cidades_labels_tmp);
        }*/
  

    }, [language]);

    // Função para interpolar entre duas cores em formato RGB
    function interpolateColor(value, minRank, maxRank) {
        // Define as cores de início e fim em formato RGB
        const startColor = [63, 169, 245]; // Azul claro (RGB para #ADD8E6)
        const endColor = [20, 24, 61];     // Azul arroxeado (RGB para #483D8B)

        // Normaliza o valor entre 0 e 1 com base no rank mínimo e máximo
        const normalizedValue = (value - minRank) / (maxRank - minRank);

        // Interpola as cores
        const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * normalizedValue);
        const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * normalizedValue);
        const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * normalizedValue);

        // Retorna a cor interpolada em formato CSS RGB
        return `rgb(${r}, ${g}, ${b})`;
    }

    // Função para remover todos os círculos do mapa
    function removeAllCircles() {
        if (circles.current.length > 0) {
            circles.current.forEach(circle => circle.remove());
            circles.current = [];  // Limpa o array após remover os círculos
        }
    }

    // Função para calcular o raio dos círculos com base no zoom
    function calculateCircleRadius(baseRadius, zoomLevel) {
        if(zoomLevel > 8) {
            return baseRadius; // Não ajusta o raio se o zoom for maior que 8
        }
        return (baseRadius * 30) / Math.pow(2, zoomLevel - 3); // Ajuste o fator de escala conforme necessário
    }

    //crie uma função que receba o valor máximo e minimo, valor atual e 5 cores, retorne a cor correspondente
    function getColor(value, min, max, colors) {
        const range = max - min;
        const step = range / (colors.length - 1);
        const index = Math.floor((value - min) / step);
        return colors[index];
    }

    /*
    useEffect(() => {
            
    }, [map, dadosGenomica]); */

    function getCodIbgeLayerShapefile(layer) {
        return (layer["feature"]["properties"]["CD_MUN"]);
    }

    const getCodEstadoBySigla = (sigla) => {
        for(let i = 0; i < cod_estados.length; i++){
            if(cod_estados[i]["UF"] === sigla){
                return cod_estados[i]["COD"];
            }
        }
    }

    const isNumeric = (value) => {
        return /^\d+(?:\.\d+)?$/.test(value); 
    }

    function carregaMunicipiosPorEstado(siglaEstado){
        if(!loading){
            setLoading(true);
        }
        /*console.log("carregaMunicipiosPorEstado");*/
        if(siglaEstado !== ''){
            fetch('Dados/lista_municipios/lista_municipios_'+siglaEstado+'.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            .then(function (response) {
                //Limpa pontos anteriores
                return response.json();
            })
            .then(function (myJson) {
                let listaTemp = [];
                let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
                for(let i = 0; i < myJson.length; i++){
                    listaTemp.push({
                        "cod_ibge": myJson[i]["COD_IBGE"],
                        "municipio": myJson[i]["MUNICIPIO"],
                        "latitude": myJson[i]["Y"],
                        "longitude": myJson[i]["X"]
                    });

                    cidades_labels_tmp.push({cod_ibge: myJson[i]["COD_IBGE"],  label: myJson[i]["MUNICIPIO"]});
                }

                setCidades_Labels(cidades_labels_tmp);

                listaTemp.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
                
                listaTemp.unshift({
                    "cod_ibge": 0,
                    "municipio": t("dashboard.todos")
                });

                setListaMunicipios(listaTemp); 

                //getDadosPorData(datas[idxDataSelecionada], language, true);
            });
        }
    }

    const getNomeEstadoByCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local) && shapeFileBrasil.current !== null && shapeFileBrasil.current["_layers"] !== null){
            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const element = shapeFileBrasil.current["_layers"][key];
                    if(element !== undefined){
                        let cod_temp = parseInt(element["feature"]["properties"]["CD_UF"]);
                        if(element["feature"]["properties"]["CD_UF"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  element["feature"]["properties"]["NM_UF"];
                            break;
                        } 
                    } else {
                        retorno = cod_ibge_local;
                    }
                }
            }  
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    function FormatarDoisDigitos(numero) {
        if(numero === null || numero === undefined)
            return "00";
        return numero.toString().padStart(2, '0');
    }

    function extraiAno(data){
        if(data && data.length > 0)
            return data.substring(0,4);
        else 
            return "";
    }

    function getDadosGenomica(){
        return dadosGenomica;
    }

    function onEachFeatureEstado(feature, layer) {
        layer.on({
            /*mouseover: function (e) {
                var layer = e.target;
                // Adiciona um popup com o nome do município

                if (feature.properties && feature.properties.NM_MUN) {
                    
                    let valor_ranking = 0;
                    let nome_var = getNomeCampoIndice(indice);
                    if(getDadosGenomica() !== null){
                        console.log("passei aqui");
                        for (let i = 0; i < getDadosGenomica() .length; i++) {
                            if(feature.properties.CD_MUN === getDadosGenomica()[i]["cod_ibge"]){
                                valor_ranking = getDadosGenomica()[i][nome_var];
                                break;
                            }
                        }
                    }
                    
                    layer.bindPopup(feature.properties.NM_MUN + " - " + feature.properties.SIGLA_UF + "<br> Ranking: " + valor_ranking, {
                        className: "popup-estilo"
                    }).openPopup();
                }
            },
            mouseout: function (e) {
                var layer = e.target;
                //shapeFileMunicipios.resetStyle(layer);
                layer.closePopup();
            },*/
            click: () => {
                if(cod_ibge === getCodIbgeLayerShapefile(layer)){
                    map.current.fitBounds(layer.getBounds().pad(1));
                    return;
                }

                setLoading(true);
   
                //resetShapeFile(estadoSelecionado, municipioSelecionado, true);

                let siglaEstadoSelecionada = feature["properties"]["SIGLA_UF"];

                let codEstadoSelecionado = getCodEstadoBySigla(siglaEstadoSelecionada);

                let nomeEstadoSelecionado = getNomeEstadoByCodIbge(codEstadoSelecionado);

                if(estadoSelecionado !== nomeEstadoSelecionado){
                    carregaMunicipiosPorEstado(siglaEstadoSelecionada);

                    setEstadoSelecionado(nomeEstadoSelecionado);
                }

                setMunicipioSelecionado(layer['feature']['properties']['NM_MUN']/*getNomeCidadeWhereCodIbge(getCodIbgeLayerShapefile(layer))*/);
                setCod_Ibge(getCodIbgeLayerShapefile(layer));

                layer.setStyle({ weight: weightSelecao, color: "black", fillOpacity: opacidadeMunicipio  });
                zoomPorClique = true;
                map.current.fitBounds(layer.getBounds().pad(1));
            },
            
        });
    }

    useEffect(() => {
        let siglaEstado = '';
        let codigo_estado = 0;

        if(shapeFileBrasil.current !== null){
            Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
                if(isAllSelect(estadoSelecionado)){
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: weightNormal, color: /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaBrasilWhite*/"black" , fillColor: /*'#E63100'*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/ transparenciaDefaultMapa, fillOpacity: 0.5 });
                } else
                if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"] === estadoSelecionado){ 
                    siglaEstado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["SIGLA_UF"];
                    codigo_estado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["CD_UF"];
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: 2, color: "black" /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaBrasilWhite/*transparenciaDefaultMapa*/, fillColor: /*'#E63100'*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/ transparenciaDefaultMapa, fillOpacity: 0.5 });
                } else {
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: weightNormal, color: /*transparenciaDefaultMapa*/"black", fillColor: /*transparenciaDefaultMapa*/ "black", fillOpacity: transparencia.current });   
                }
            });
        }

        if(isAllSelect(estadoSelecionado) && (!isAllSelect(municipioSelecionado) || isAllSelect(municipioSelecionado))){
            const defaultCenter = position;
            const { innerWidth: width, innerHeight: height } = window;
            if(width < 1300)
                map.current.setView([-13.338793, -45.206666], defaultZoom);
            else 
                map.current.setView(defaultCenter, defaultZoom);
        }

        if(isAllSelect(estadoSelecionado) && !isAllSelect(municipioSelecionado)){
            setMunicipioSelecionado(t("dashboard.todos"));
        }

        if(!isAllSelect(municipioSelecionado)){
            return;
        }

        setLoading(true);

        if(shapeFileBrasil.current !== null){
            if(codigo_estado === 0){
                setListaMunicipios(getCidades());
                let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
                setCidades_Labels(cidades_labels_tmp);
            }
            
            carregaDadosEstado(codigo_estado, siglaEstado);
        }
        setLoading(false);
    }, [estadoSelecionado, municipioSelecionado]);

    function carregaDadosEstado(codigo_estado, siglaEstado){
        if(!loading){
            setLoading(true);
        }
        
        if(codigo_estado !== 0){

            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const element = shapeFileBrasil.current["_layers"][key];
                    if(element !== undefined && element["feature"]["properties"]["NM_UF"] === estadoSelecionado){
                        map.current.fitBounds(element.getBounds());
                        break;
                    } 
                }
            } 

            carregaMunicipiosPorEstado(siglaEstado);
        } 
    }

    const plotaMapa = () => {
        Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
            var cod_ibge_tmp = getCodIbgeShapefile(shapeFileMunicipios.current, shape);
            let transparenciaFill = /*theme === "dark" ? "#8d8d8f" : "#e5e5e6"*/"rgba(255,255,255,0)";
            
            var cor = transparenciaFill;
            var corContorno = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda;
    
            let weight_ = weightNormal;
            
            if (cod_ibge === shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["CD_MUN"]) {
                cor = transparenciaFill;
                corContorno = /*theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda*/"black";
                weight_ = weightSelecao;     
            }

            shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weight_, color: corContorno, fillColor: cor, fillOpacity: opacidadeMunicipio});                                
        });   

        setLoading(false); 
    }

    const handleChangeIndicador = (event) => {
        setIndice(event.target.value);
    }

    const [listaSinaisSoc, setlistaSinaisSoc] = useState(
        [
            {"sinal": "mob", "nome":  t("dashboard.mobilidade")},
            /*{"sinal": "den", "nome":  t("dashboard.densidade")},
            {"sinal": "ibp", "nome":  t("dashboard.ibp")},
            {"sinal": "dsei", "nome": t("dashboard.dsei")},*/
        ]
    );

    const getNomeCampoIndice = (indice) => {
        let nome_var = "rank_mob_den_ibp_dsei";
        
        if(selectedRank.length === 4){
            nome_var = "rank_mob_den_ibp_dsei";
        } else if(selectedRank.includes(t("dashboard.densidade")) && selectedRank.includes(t("dashboard.ibp"))){
            nome_var = "rank_mob_den_ibp";
        } else if(selectedRank.includes(t("dashboard.densidade")) && selectedRank.includes(t("dashboard.dsei"))){
            nome_var = "rank_mob_den_dsei";
        } else if(selectedRank.includes(t("dashboard.ibp")) && selectedRank.includes(t("dashboard.dsei"))){
            nome_var = "rank_mob_ibp_dsei";
        } else if(selectedRank.includes(t("dashboard.densidade"))){
            nome_var = "rank_mob_den";
        } else if(selectedRank.includes(t("dashboard.ibp"))){
            nome_var = "rank_mob_ibp";
        } else if(selectedRank.includes(t("dashboard.dsei"))){
            nome_var = "rank_mob_dsei";
        } else {
            nome_var = "rank_mob";
        }

        return nome_var;
    };

    useEffect(() => {
        if(map.current === null || dadosGenomica === null)
            return;

        let nome_var = getNomeCampoIndice(indice);
        /*if(indice === "mob"){
            if(selectedRank.length === 3){
                nome_var = "rank_mob_den_ibp_dsei";
            } else if(selectedRank.includes(t("dashboard.densidade")) && selectedRank.includes(t("dashboard.ibp"))){
                nome_var = "rank_mob_den_ibp";
            } else if(selectedRank.includes(t("dashboard.densidade")) && selectedRank.includes(t("dashboard.dsei"))){
                nome_var = "rank_mob_den_dsei";
            } else if(selectedRank.includes(t("dashboard.ibp")) && selectedRank.includes(t("dashboard.dsei"))){
                nome_var = "rank_mob_ibp_dsei";
            } else if(selectedRank.includes(t("dashboard.densidade"))){
                nome_var = "rank_mob_den";
            } else if(selectedRank.includes(t("dashboard.ibp"))){
                nome_var = "rank_mob_ibp";
            } else if(selectedRank.includes(t("dashboard.dsei"))){
                nome_var = "rank_mob_dsei";
            } else {
                nome_var = "rank_mob";
            }
        } else  if (indice === "den") {
            nome_var = "rank_den";
        } else  if (indice === "ibp"){
            nome_var = "rank_ibp";
        } else if (indice === "dsei"){
            nome_var = "rank_dsei";
        }*/

        if (map.current !== null && dadosGenomica !== null) {
            // Remova todos os círculos existentes
            removeAllCircles();
    
            const minRank = Math.min(...dadosGenomica.map(d => d.rank_mob_den_ibp_dsei));
            const maxRank = Math.max(...dadosGenomica.map(d => d.rank_mob_den_ibp_dsei));
    
            // Função para adicionar círculos ajustados ao mapa
            const addCircles = () => {
                const zoomLevel = map.current.getZoom();
                if(dadosGenomica){
                    for (let i = 0; i < dadosGenomica.length; i++) {
                        const rankValue = maxRank-dadosGenomica[i][nome_var];
                        //const circleColor = interpolateColor(rankValue, minRank, maxRank);
                        //const circleColor = getColor(rankValue, minRank, maxRank, ["#3fa9f5", "#3891d6", "#3179b8", "#2a6199", "#22487a", "#1b305b", "#14183d", "#0d001e"]);
                        const circleColor = getColor(rankValue, minRank, maxRank, ["#169ADB", "#1594D3", "#127CB1", "#0E648F", "#0B4C6D", "#3E4447"]);
                        
                        const baseRadius = rankValue * 10; // Raio base do círculo
                        const adjustedRadius = calculateCircleRadius(baseRadius, zoomLevel); // Ajusta o raio com base no zoom

                        //se ajustedRadius for nan ou infinito, não plote
                        if(isNaN(adjustedRadius) || !isFinite(adjustedRadius)){
                            continue;
                        }
                        
                        //pesquise no shapefile de municipios o cod_ibge e pegue o nome do municipio
                        let nome_municipio = getNomeEUFCidadeWhereCodIbge(dadosGenomica[i]["cod_ibge"]);

                        // Adiciona novo círculo ao mapa
                        const circle = L.circle([dadosGenomica[i]["centroid_y"], dadosGenomica[i]["centroid_x"]], {
                            color: circleColor,
                            fillColor: circleColor,
                            fillOpacity: 0.7,
                            radius: adjustedRadius,
                            weight: 1
                        }).addTo(map.current);

                        // Adicione um popup ao círculo (opcional)
                        //circle.bindPopup(nome_municipio + "<br>" + nome_var+": " + dadosGenomica[i][nome_var]);
                        
                        circle.on('mouseover', function (e) {
                            //circle.openPopup();
                            var layer = e.target;
                            // Adiciona um popup com o nome do município
                            
                            layer.bindPopup(nome_municipio[0] + " - " + nome_municipio[1] + "<br> Ranking: " + dadosGenomica[i][nome_var], {
                                className: "popup-estilo"
                            }).openPopup();

                            
                        });

                        circle.on('mouseout', function (e) {
                            circle.closePopup();
                        });

                        /*mouseover: function (e) {
                            var layer = e.target;
                            // Adiciona um popup com o nome do município
                            if (feature.properties && feature.properties.NM_MUN) {
                                layer.bindPopup(feature.properties.NM_MUN + " - " + feature.properties.SIGLA_UF, {
                                    className: "popup-estilo"
                                }).openPopup();
                            }
                        },
                        mouseout: function (e) {
                            var layer = e.target;
                            //shapeFileMunicipios.resetStyle(layer);
                            layer.closePopup();
                        },*/

                        // Adicione o círculo ao array de referências
                        circles.current.push(circle);
        
                    }
                }
            };
    
            // Adiciona os círculos inicialmente
            addCircles();
    
            // Atualiza os círculos quando o mapa for ampliado ou reduzido
            map.current.on('zoomend', () => {
                removeAllCircles();
                addCircles();
            });

            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                for (let i = 0; i < dadosGenomica.length; i++) {
                    if (parseInt(getCodIbgeShapefile(shapeFileMunicipios.current, shape)) === parseInt(dadosGenomica[i]["cod_ibge"])) {
                       
                        let conteudo = shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["NM_MUN"] + " - " + shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["SIGLA_UF"] + "<br> Ranking: " + dadosGenomica[i][nome_var];
                        //console.log("a", shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["NM_MUN"], conteudo);
                        if(shapeFileMunicipios.current["_layers"][shape].getPopup()){
                            /*console.log("update");*/
                            shapeFileMunicipios.current["_layers"][shape].getPopup().setContent(conteudo);
                            shapeFileMunicipios.current["_layers"][shape].getPopup().update();
                        } else {
                            /*console.log("create", conteudo);*/
                            shapeFileMunicipios.current["_layers"][shape].bindPopup(conteudo, {autoPan:false, className: 'popup-estilo'});
                        }

                        shapeFileMunicipios.current["_layers"][shape].on('mouseover', function(e){ 
                            
                            /*console.log("mouseover", conteudo, dadosGenomica[i][nome_var]);*/
                            var popup = e.target.getPopup();
                            popup.setLatLng(e.latlng).openOn(map.current); 
                        });
                        shapeFileMunicipios.current["_layers"][shape].on('mousemove', function(e){ 
                            var popup = e.target.getPopup();
                            popup.setLatLng(e.latlng).openOn(map.current);
                        });
                        shapeFileMunicipios.current["_layers"][shape].on('mouseout', function(){ 
                            this.closePopup(); 
                        });
                    
                        break;
                    }
                }
            });
    
           /*// Remove o listener de zoom quando o componente é desmontado
            return () => {
                map.current.off('zoomend');
            };*/
        }

        
    }, [dadosGenomica, indice, selectedRank]);

    return (
        <Container className="container_Mapa">
            {loading && <div className={style.loading}><div className={style.lds_ring}><div></div><div></div><div></div><div></div></div></div>}

            <Row>
                <Row>
                    <div className={style.div_top}>
                        <br></br>

                        <div className={style.container_seleciona_municipio_e_cor}>
                            <div className={style.seleciona_municipio}> 
                                <Autocomplete
                                    {...defaultProps}
                                    disableClearable
                                    id="autocomplete_estado"
                                    className='change_color_text'
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    value={{label:estadoSelecionado}}
                                    onChange={(event, newValue) => {
                                        setLoading(true);
                                        setEstadoSelecionado(newValue.label);
                                        setCod_Ibge(t("dashboard.todos"));
                                        setMunicipioSelecionado(t("dashboard.todos"));
                                    }}
                                    renderInput={(params) => (
                                        <TextField className='autocomplete_estado_textfield' {...params} label={t("dashboard.estado")} size="small"/>
                                    )}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                
                                <Autocomplete
                                    {...defaultPropsMunicipio}
                                    disableClearable
                                    id="autocomplete_municipio"
                                    className='change_color_text'
                                    isOptionEqualToValue={(option, value) => option.cod_ibge === value.cod_ibge}
                                    value={{cod_ibge:cod_ibge, label:municipioSelecionado}}
                                    onChange={(event, newValue) => {
                                        handleChangeMunicipioNome(newValue.cod_ibge);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t("dashboard.cidade")} size="small"/>
                                    )}
                                />
                                
                                <FormControl id="multipleselect_form" style={{display:indice === "mob" ? "block" : "none"}} >
                                    <InputLabel>{t("dashboard.titulo_ranking")}</InputLabel>
                                    <Select
                                        id="multipleselect_select"
                                        multiple
                                        value={selectedRank}
                                        sx={{ width: 400}}
                                        onChange={(e) => setSelectedRank(e.target.value)}
                                        input={<OutlinedInput label={t("dashboard.titulo_ranking")} />}
                                        renderValue={(selected) => (
                                            <Stack id="multipleselect_stack" gap={1} direction="row" flexWrap="wrap">
                                                {selected.map((value) => (
                                                <Chip
                                                    id="multipleselect_chip"
                                                    key={value}
                                                    label={value}
                                                    sx={theme === "dark" ? { color: "white", backgroundColor: "#2f3136" } : {  }}
                                                    onDelete={() => {
                                                            
                                                            /*setSelectedRank(
                                                                selectedRank.filter((item) => item !== value))*/
                                                            if(value !== t("dashboard.mobilidade")){
                                                                setSelectedRank(
                                                                    selectedRank.filter((item) => item !== value || item === t("dashboard.mobilidade"))
                                                                );
                                                            }
                                                        }
                                                    }
                                                    deleteIcon={
                                                        <CancelIcon
                                                            id="multipleselect_cancelicon"
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                />
                                                ))}
                                            </Stack>
                                        )}
                                        
                                    >
                                        {val_Rank.map((name) => (
                                            name !== t("dashboard.mobilidade") && (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            sx={{ justifyContent: "space-between"}}
                                        >
                                            {name}
                                            {selectedRank.includes(name) ? <CheckIcon id="multipleselect_checkicon" color="info" /> : null}
                                        </MenuItem>
                                        )))}
                                    </Select>
                                    
                                </FormControl>

                            </div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className={style.mapa}>
                            <div id="map" className={style.map}>

                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='espacamento'></div>  
            </Row>
            <Footer></Footer>
        </Container>
    )
}

export default Metagenomica