import { Biohazard } from "lucide";

export default {
    translations: { // Mesmo valor usado nas configurações (defaultNS)
      home: {
        message: 'Hello World!'
      },
      sankey: {
        sem_dados: 'No data available',
        col1tab1: 'Hub City by Path Proportion',
        col2tab1: 'State of Hub City',
        col3tab1: 'Hub City IBGE Code',
        col4tab1: 'Proportion of Paths Leading to The Hub',
        col1tab2: 'Gateway City',
        col2tab2: 'IBGE Code of Gateway City',
        col3tab2: 'City to be Affected',
        col4tab2: 'Federative Unity to be Affected',
        col5tab2: 'IBGE Code of City to be Affected',
        col6tab2: 'Proportion of Paths Leading to the Affetced Cities',
        col1tab3: 'Suggested Cities for Pathogen Early Detection From the Selected City',
        col2tab3: 'State of Hub City',
        col3tab3: 'Hub City IBGE Code',
        col4tab3: 'Proportion of Paths Leading to the Hub',
      },
      dashboard: {
        //title: 'Monitoring Upper Respiratory Infection in Primary Healthcare (PHC) in Brazil',
        //subtitle: 'Overview of Upper Respiratory Infections (URI) encounters',
        titulo_ranking: 'Ranking of Municipalities',
        titulo_principal: 'Main',
        titulo_acessorio: 'Accessory',
        mobilidade: 'Mobility',
        densidade: 'Density',
        ibp: 'IBP',
        dsei: 'DSEI',
        otc: 'OTC',
        a: 'to',
        e: 'and',
        title: 'Tracking of Encounters Probably Due to Influenza-like Illness in The Primary Health Care (PHC) in Brazil',
        subtitle: '',
        title_resumo: 'Summary Dashboard',
        title_climate: 'Climate Dashboard',
        title_metagenomic: 'Metagenomic Dashboard',
        queda: 'Decreasing',
        estavel: '&nbsp;&nbsp;Stable&nbsp;&nbsp;',
        alerta: 'Atention',
        alta : 'Increasing',
        data : 'Date',
        buscar_uf: 'Filter UF',
        buscar_municipio: 'Filter Municipality',
        cidade : 'Municipality',
        estado : 'State',
        populacao: 'Population',
        atencao: 'Attention',
        aviso: 'Warning',
        semana_ep: 'Epidemiological Week',
        semana: 'Week',
        semanas: 'Weeks',
        tosse:'Cough',
        febre:'Fever',
        dqi: 'DQI',
        ears: 'EARS',
        evi_simples: 'EVI',
        ambos: 'Both',
        sem_aviso: 'No Warning',
        aviso_consecutivo: 'Consecutive Warning',
        apto: 'Validated',
        nao_apto: 'Not Validated',
        no_data: 'No Data',
        aptos: 'Validated',
        nao_aptos: 'Not Validated',
        nao_validado: 'Not Validated',
        probabilidade_crescimento: 'Growth Probability ',
        sem_informacao: 'No information',
        titulo_mapa_taxa_crescimento: 'Growth Rate of ILI encounters',
        titulo_mapa_atendimentos_excedentes : 'Exceeding ILI Encounters',
        titulo_mapa_evi: 'Warning Sign',
        evi: 'EVI (Epidemic Volatility Index)',
        legenda_mapa_taxa_crescimento: 'Growth rate (%)',
        legenda_mapa_atendimentos_excedentes: 'Exceeding Encounters',
        perc_completude: 'Completeness (%)',
        perc_tempestividade: 'Timing (%)',
        retangulos:{
          titulo_excesso : 'Exceeding ILI Encounters',
          mil: 'K',
          milhoes: 'M',
          bilhoes: 'B',
        },
        dados_climaticos: 'Climate Data',
        graficos: {
          titulo_mm: 'ILI Encounters Proportion',
          legenda_mm: {
            1:'Moving Average',
            2:'Proportion (ILI Encounters/Total Encounters)',
          },
          titulo_variacao_mm : 'Growth Rate of ILI Proportion (Moving Average)',
          titulo_atendimento_gripais : 'ILI Encounters in PHC',
          titulo_atendimento_totais : 'Total Encounters in PHC',
          titulo_excesso : 'Exceeding ILI Encounters from Expected Limit',
          titulo_limite : 'ILI Encounters Expected Limit',
          titulo_precipitacao : 'Precipitation',
          titulo_humidade : 'Humidity',
          titulo_indice_calor :'Heat Index',
          titulo_temp2m :'Temperature at 2 meters',
          titulo_med_movel_precipitacao : 'Moving Average Precipitation',
          titulo_med_movel_humidade : 'Moving Average Humidity',
          titulo_med_movel_indice_calor :'Moving Average Heat Index',
          titulo_med_movel_temp2m :'Moving Average Temperature at 2 meters',
          titulo_tosse_febre: 'Cough And Fever',
          titulo_temp: 'Average Daily Temperature ºC',
          titulo_um: 'Relative humidity %',
          titulo_prec: 'Corrected Total Precipitation mm',
        },
        table: {
          titulo: 'Enquiry of Indicators in the Municipalities',
          titulo_estados: 'Enquiry of Indicators in the Municipalities',
          col1: 'Municipality',
          col2: 'Population',
          col3: 'Growth Rate (ILI ratio)',
          col9: 'EVI (Epidemic Volatility Index)',
          col4: 'Exceeding ILI Encounters',
          col5: 'Expected Limit of Encounters (ILI)',
          col6: 'ILI Encounters Proportion',
          col7: 'ILI Encounters',
          col8: 'Total Encounters',
          estado: 'UF',
          limite: 'Limit',
          limite_ambos: 'Limit EVI & EARS',
          excesso: 'Exceeding',
          excesso_ambos: 'Exceeding EVI & EARS',
          evi: 'EVI',
          ears: 'EARS',
          ens: 'ENSEMBLE',
          ml: 'ML',
          glm: 'GLM',
          sinal_evi: 'Consecutive EVI Signal',
          sinal_ears: 'Consecutive EARS Signal',
          sinal_ambos: 'Consecutive EVI & EARS Signal',
          sinal_ens: 'Consecutive ENSEMBLE Signal',
          sinal_ml: 'Consecutive ML Signal',
          limite_ml: 'ML Limit',
          excesso_ml: 'ML Exceeding',
          sinal_glm: 'Consecutive GLM Signal',
          limite_glm: 'Limit GLM',
          excesso_glm: 'Exceeding GLM',
          sinais: 'Total Signals',
        },
        todos: 'All',
        mensagemNaoFoiPossivelCalcularTaxaCrescimento: 'Unable to calculate growth rate',
        subMensagemNaoFoiPossivelCalcularTaxaCrescimento: 'Undef',
        mesAno: 'Month/Year: ',
        flowMapNomeLayerMob: 'Mobility Routes',
        semanal: 'Weekly',
        mensal: 'Monthly',
        excesso: 'Exceeding ILI Encounters from Expected Limit',
        excesso_simples: 'Exceeding',
        gripe: 'Flu',
        arbo: 'Arbo',
        legenda: 'Legend',
        variaveisClima:{
          Bio01BR: "Mean Annual Air Temperature",
          Bio02BR: "Mean Diurnal Air Temperature Range",
          Bio03BR: "Isothermality",
          Bio04BR: "Temperature Seasonality",
          Bio05BR: "Mean Daily Maximum Air Temperature of The Warmest Month",
          Bio06BR: "Mean Daily Minimum Air Temperature of The Coldest Month",
          Bio07BR: "Annual Range of Air Temperature",
          Bio08BR: "Mean Daily Mean Air Temperatures Of The Wettest Quarter",
          Bio09BR: "Mean Daily Mean Air Temperatures of The Driest Quarter",
          Bio10BR: "Mean Daily Mean Air Temperatures of The Warmest Quarter",
          Bio11BR: "Mean Daily Mean Air Temperatures of The Coldest Quarter",
          Bio12BR: "Annual Precipitation Amount",
          Bio13BR: "Precipitation Amount of The Wettest Month",
          Bio14BR: "Precipitation Amount of The Driest Month",
          Bio15BR: "Precipitation Seasonality",
          Bio16BR: "Mean Monthly Precipitation Amount of The Wettest Quarter",
          Bio17BR: "Mean Monthly Precipitation Amount of The Driest Quarter",
          Bio18BR: "Mean Monthly Precipitation Amount of The Warmest Quarter",
          Bio19BR: "Mean Monthly Precipitation Amount of The Coldest Quarter",
        },
        variaveisGoogle:{
          coriza: "Runny Nose",
          covid: "Covid",
          diarreia: "Diarrhea",
          dor_de_cabeca: "Headache",  
          dor_de_garganta: "Sore Throat",
          falta_de_ar: "Shortness of Breath",
          febre: "Fever",
          gripe: "Flu",
          resfriado: "Cold",
          tosse: "Cough",
          vomito: "Vomiting",
          termos: "Terms",
        },
        variavel: "Variable",
      }
    }
  }